@charset "utf-8";
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/*
.
*/
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
* {
  margin: 0;
  padding: 0;
}
body {
  height: 100%;
  margin: 0;
  font-size: 14px;
  text-align: center;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ",
    Meiryo, "ＭＳ Ｐゴシック";
  position: relative;
}
p {
  font-size: 12px;
  line-height: 160%;
}

/* リンク色 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

a {
  color: #06f;
  text-decoration: underline;
  transition: 0.3s;
}
a:hover {
  color: #f60;
  text-decoration: underline;
}

/* ボタン */
.btn-square {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  background: #36a34b; /*ボタン色*/
  color: #fff;
  border-bottom: solid 4px #1a6e2a;
  border-radius: 3px;
  line-height: 1.2;
  font-size: 11px;
}
.btn-square:hover {
  /*ボタンを押したとき*/
  -webkit-transform: translateY(4px);
  transform: translateY(4px); /*下に動く*/
  text-decoration: none;
  border-bottom: none; /*線を消す*/
}
a.btn-square:hover {
  color: #fff;
}

.btn-inpage-link {
  display: block;
  box-sizing: border-box;
  background: #36a34b; /*ボタン色*/
  border-radius: 50px;
  width: 100%;
  margin: 3rem 0;
  padding: 1em;
  color: #fff;
  text-align: center;
  text-decoration: none;
}
.btn-inpage-link:hover {
  text-decoration: none;
  color: #fff;
  opacity: 0.7;
}
.btn-totop-link {
  display: block;
  max-width: 400px;
  width: 80%;
  margin: 0 auto 1rem;
  padding: 0.5em;
  background: #feb2d6;
  border-radius: 10rem;
  color: #000;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
}
.btn-totop-link:hover {
  text-decoration: none;
  color: #000;
  opacity: 0.7;
}

/* 色 */
.yellow {
  background: #fbf8e8;
}

/* 画像 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

img {
  border: none;
  margin: 0;
  padding: 0;
}
a img {
  border: none;
}
.imgl {
  padding: 0px 0px 20px 20px;
  float: right;
}
.imgr {
  padding: 0px 0px 20px 20px;
  float: right;
}
.imgc {
  width: 100%;
  padding: 0 6%;
  text-align: center;
  clear: both;
}
.ichachi {
  margin: 0px 20px 20px 0px;
  padding: 5px;
  float: left;
  border: 1px #ccc solid;
  box-shadow: 0 0 6px #ccc, 0 0 6px #fff;
  -webkit-box-shadow: 0 0 6px #d7d7d7, 0 0 6px #fff;
}

.left {
  text-align: left;
  float: left;
}
.right {
  text-align: right;
  float: right;
}

.alignleft {
  padding: 0 20px 20px 0;
  float: left;
}

.alignright {
  padding: 0 0px 20px 20px;
  float: right;
}

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  clear: both;
}
.l-flex {
  display: flex;
}
.u-align-c {
  text-align: center;
}
.u-bold {
  font-weight: bold;
}
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* h1サイトキーワード設定 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#h1_inframe {
  width: 100%;
  background: #fcaeae;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

h1 {
  width: 970px;
  margin: 0 auto;
  font-size: 11px;
  font-style: normal;
  line-height: 180%;
  color: #ffffff;
  padding: 5px 0;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

h2 {
  max-width: 620px;
  margin: 0;
  text-align: left;
  background-color: #ffffff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  -moz-border-radius-bottomleft: 6px;
  -moz-border-radius-bottomright: 6px;
  float: left;
  overflow: hidden;
}
h2 a {
  margin: 0;
  padding: 0;
  color: #333;
  text-shadow: 0px 2px 2px #ccc;
  text-decoration: none;
  overflow: hidden;
}
h2 a:hover {
  color: #f60;
  color: #ff5d95;
  text-shadow: 0px 1px 1px #ff95ba;
  text-decoration: underline;
}
#fb-root {
  clear: both;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* メインバナー設定 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#main_box3,
#main_box2l,
#main_box2r,
#main_box,
#main_boxk3,
#main_boxk2l,
#main_boxk2r,
#main_box1,
#main_boxk1 {
  width: 970px;
  min-height: 200px;
  margin: 0 auto 5px;

  border: 1px #ffffff solid;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}
#main_banner {
  display: block;
  height: 510px;
  width: 100%;
  background: url(../img/main_bg.jpg) no-repeat center 0;
}
#main_banner img {
  width: 950px;
  margin: 0 auto;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/*サイト説明設定*/
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#blog_explain_box {
  width: 680px;
  padding: 0;
  text-align: left;
}
#blog_explain_box p {
  font-size: 13px;
  padding: 8px 10px;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* 本体設定 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#contents_in {
  width: 970px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  clear: both;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* グローバルナビ設定 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
.menubtn {
  display: none;
}
.togmenu {
  display: block;
}
#global_n_box {
  width: 100%;
  height: 60px;
  margin: 10px auto 0;
  padding: 0;
  list-style-type: none;
}
#global_n_box ul {
  width: 950px;
  height: 60px;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  text-align: center;
  background: url(../img/gnavi.png) top repeat-x;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  clear: both;
}
#global_n_box ul li {
  width: 20%;
  height: 60px;
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 60px;
  text-align: center;
  position: relative;
  float: left;
}
#global_n_box ul li.first a,
#global_n_box ul li a,
#global_n_box ul li.last a {
  height: 60px;
  padding: 0 10px;
  color: #fff;
  text-shadow: 1px 1px 1px #ff95ba;
  font-weight: bold;
  text-decoration: none;
  display: block;
  overflow: hidden;
  background: none;
}
#global_n_box ul li a:hover {
  width: 84%;
  margin: 5px;
  height: 50px;
  line-height: 50px;
  text-decoration: none;
  background: url(../img/gnavi.png) bottom repeat-x;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}

/* ------------------------------------------------------------------------ */

#global_n_box ul.sub-menu {
  width: 100%;
  margin: 0;
  display: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  z-index: 99999;
  list-style: none;
}
#global_n_box ul.sub-menu li {
  width: 100%;
  border-left: none;
  list-style-type: none;
  background: url(../img/gnavi.png) top repeat-x;
}
#global_n_box ul.sub-menu li a {
  float: left;
  width: 90%;
  color: #444;
  display: block;
  font-weight: bold;
  border-radius: none;
  background: url(../img/gnavi.png) top repeat-x;
}
#global_n_box ul.sub-menu li a:hover {
  float: left;
  width: 84%;
  font-weight: bold;
  background: url(../img/gnavi.png) bottom repeat-x;
  border-radius: none;
}
#global_n_box .menu ul ul a {
  width: 100%;
  color: #444;
  font-size: 13px;
  font-weight: bold;
  height: auto;
  line-height: 60px;
  padding: 0;
  height: 60px;
  border-bottom: 1px #28326d dotted;
}
#global_n_box .menu ul ul a:hover {
  float: left;
  width: 96%;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  background: url(../img/gnavi.png) bottom repeat-x;
  border-radius: none;
}
#global_n_box .menu ul ul {
  width: 100%;
  left: 100%;
  top: 0;
}
#global_n_box .menu ul.menu ul.sub-menu ul.sub-menu {
  margin: 0 0 0 100%;
  width: 100%;
  list-style: none;
}
#global_n_box .menu ul.menu ul.sub-menu ul.sub-menu li a {
  width: 90%;
  color: #444;
  font-size: 13px;
  font-weight: bold;
  height: auto;
  line-height: 60px;
  padding: 0;
  height: 60px;
  border-bottom: 1px #28326d dotted;
}
#global_n_box .menu ul.menu ul.sub-menu ul.sub-menu li a:hover {
  float: left;
  width: 95%;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  background: url(../img/gnavi.png) bottom repeat-x;
  border-radius: none;
}

/* ------------------------------------------------------------------------ */

#global_n_box li:hover > a,
#global_n_box ul.sub-menu :hover > a,
#global_n_box a:focus {
  background: url(../img/gnavi.png) bottom repeat-x;
}
#global_n_box ul li:hover > a,
#global_n_box ul a:focus {
  color: #ff6600;
  font-weight: bold;
  list-style: none;
  background: url(../img/gnavi.png) bottom repeat-x;
}
#global_n_box ul.menu li:hover > ul {
  background: url(../img/gnavi.png) bottom repeat-x;
  display: block;
}

/* ------------------------------------------------------------------------ */

#global_n_box .menu ul.menu ul.sub-menu ul.sub-menu li a:hover {
  background: url(../img/gnavi.png) bottom repeat-x;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* パンくずリスト設定 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#breadcrumb {
  margin: 10px;
  padding: 0;
  text-align: left;
  background: #fff;
  clear: both;
}
#breadcrumb p {
  width: 930px;
  margin: 0 auto;
  padding: 7px 0;
  color: #444;
  line-height: 15px;
  font-size: 11px;
  font-weight: bold;
  background: #fff;
}
#breadcrumb a {
  color: #444;
  text-decoration: none;
}
#breadcrumb a:hover {
  color: #f60;
  text-decoration: none;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* メインコンテンツ設定 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#left_con {
  width: 725px;
  margin: 0;
  float: left;
  background: #fff;
}
#right_con {
  width: 245px;
  margin: 0;
  float: right;
  background: #fff;
}

/* 3カラム ---------------------------------------------------------------- */

#main_box3 #main_contents_box,
#main_boxk3 #main_contents_box {
  width: 451px;
  margin: -10px 0 0;
  padding: 10px;
  border-left: 1px #28326d dotted;
  border-right: 1px #28326d dotted;
  background: #fff;
  float: right;
}
#main_box3 #left_contents_box,
#main_boxk3 #left_contents_box {
  width: 240px;
  margin: 10px 0;
  background: #fff;
  float: left;
}
#main_box3 #right_contents_box,
#main_boxk3 #right_contents_box {
  width: 240px;
  margin: 10px 0;
  background: #fff;
  float: right;
}

/* 2カラム左 -------------------------------------------------------------- */

#main_box2l #main_contents_box,
#main_boxk2l #main_contents_box {
  width: 691px;
  margin: -10px 0 0;
  padding: 10px;
  border-left: 1px #28326d dotted;
  background: #fff;
  float: right;
}
#main_box2l #left_contents_box,
#main_boxk2l #left_contents_box {
  width: 240px;
  margin: 10px 0;
  background: #fff;
  float: left;
}

/* 2カラム右 -------------------------------------------------------------- */

#main_box2r #main_contents_box,
#main_boxk2r #main_contents_box {
  width: 691px;
  margin: -10px 0 0;
  padding: 10px;
  /*border-right:1px #FF95BA dotted;*/
  background: #fff;
  float: left;
}
#main_box2r #right_contents_box,
#main_boxk2r #right_contents_box {
  width: 240px;
  margin: 10px 0;
  background: #fff;
  float: right;
}

/* 1カラム ---------------------------------------------------------------- */

#main_box1 #main_contents_box,
#main_boxk1 #main_contents_box {
  width: 691px;
  margin: 0 auto;
  padding: 0;
  background: #fff;
  clear: both;
}

/* ------------------------------------------------------------------------ */

.line_box {
  margin: -12px;
  padding: 0 9px 0 10px;
  background: none;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}

/* n_poits -------------------------------------------------------------- */
.n_points {
  border: solid 2px #ffbd68;
  margin-bottom: 30px;
  /* box-shadow */
  box-shadow: 0px 2px 3px 2px #eaeaea;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  text-align: justify;
  text-justify: inter-ideograph;
}
.n_points .n_title {
  position: absolute;
  top: 20px;
  left: -20px;
}
.n_points dl {
  margin-top: 70px;
  margin-bottom: 15px;
}
.n_points dl dt {
  display: table;
  margin-bottom: 15px;
}
.n_points dl dt p {
  display: table-cell;
  font-weight: bold;
  line-height: 1.4;
}
.n_points dl dt p.sub {
  background: #ffbd68;
  font-size: 14px;
  color: #fff;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
  vertical-align: middle;
}
.n_points dl dt p.detail {
  font-size: 18px;
  padding-left: 7px;
}
.n_points dl dt span {
  background: linear-gradient(transparent 60%, #ffff66 60%);
}
.n_points span.b {
  font-weight: bold;
}

.n_points_inner .inner {
  display: none;
}
.n_points_inner .inner.open {
  display: block;
}
.n_points_inner li {
  list-style: none;
  margin-bottom: 15px;
}
.n_points_inner li p {
  margin-bottom: 15px;
  cursor: pointer;
}
.n_points_inner li.content {
  display: table;
}
.n_points_inner li .txt,
.n_points_inner li img {
  display: table-cell;
  vertical-align: middle;
}
.n_points_inner li .txt {
  width: 460px;
}
.n_points_inner li .img {
  width: 140px;
  display: block;
}
.n_points_inner .caution,
.n_points_inner .caution p {
  font-size: 11px;
  line-height: 1.6;
}
.n_points_inner .caution {
  border: solid 1px #e3e3e3;
  padding: 15px;
  margin-top: 15px;
}
.n_points_inner .caution.normal {
  border: none;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* 名前固定コンテンツ設定 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.entry-title,
.comment_box h3,
.column_title {
  margin: 0 auto 10px;
  padding: 8px 10px 6px 25px;
  color: #504233;
  text-align: left;
  font-size: 19px;
  line-height: 28px;
  background: url(../img/back2.png) left top repeat-y;
  clear: both;
}
.entry-title a {
  color: #333;
  text-decoration: none;
}
.entry-title a:hover {
  color: #ff5d95;
  text-shadow: 0px 2px 2px #ff95ba;
  text-decoration: none;
}
.econtent,
.econtent-i {
  margin: 0 0 10px;
  _margin: 0 0 5px;
  padding: 10px 20px 0;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #555;
  border: none;
  background: #fff url(../img/note_line.png) repeat;
  overflow: hidden;
  clear: both;
}
.econtent-none,
.econtent-none-i {
  margin: 0px 0 10px;
  _margin: 0 0 5px;
  padding: 10px 0px 0;
  font-size: 14px;
  line-height: 160%;
  text-align: left;
  color: #555;
  border: none;
  background: #fff;
  overflow: hidden;
  clear: both;
}
.econtent-none p,
.econtent-none-i p,
.econtent p,
.econtent-i p {
  margin: 0;
  font-size: 14px;
  line-height: 160%;
  text-align: left;
}
.econtent ol,
.econtent ul,
.econtent-none ol,
.econtent-none ul,
.econtent-none-i ol,
.econtent-none-i ul,
.econtent-i ol,
.econtent-i ul {
  list-style-position: inside;
  padding: 0 0 0 10px;
}
.title {
  margin: 0 0 8px;
  color: #504233;
  padding: 2px 0 2px 12px;
  font-size: 15px;
  text-align: left;
  line-height: 18px;
  border-left: 10px #fcaeae solid;
  clear: both;
}
.menu-navi1-container {
  padding-bottom: 10px;
}

.econtent img,
.econtent-i img,
.econtent-none img,
.econtent-none-i img {
  max-width: 99%;
  height: auto;
}
.social_button img {
  width: 88px;
  height: 20px;
}
iframe.twitter-share-button {
  width: 88px !important;
}
.video-wrap {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 100%;
  overflow: hidden;
}
.video-wrap iframe,
　.video-wrap object,
　.video-wrap embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.common_contents {
  margin: 0;
  padding: 0;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* 見出し設定設定 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

h3 {
  margin: 0 10px 10px;
  *margin: 0 5px 5px;
  padding: 8px 0 8px 40px;
  text-shadow: none;
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
  background: url(../img/h3_mark.png) left center no-repeat;
  clear: both;
}
h4 {
  margin: 10px;
  *margin: 5px;
  padding: 11px 10px;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  border-left: 7px #ff95ba solid;
  border-bottom: 3px #ff95ba solid;
  clear: both;
}
h5 {
  margin: 9px 10px 7px;
  *margin: 4px 5px 3px;
  padding: 6px 10px 2px 30px;
  font-size: 15px;
  font-weight: bold;
  background: url(../img/h5_mark.png) 5px center no-repeat;
  clear: both;
}
.sec_title {
  color: #fbadad;
  display: block;
}
.rankingverb p.sec_aller_title {
  background: #fbadad;
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 5px;
  margin-bottom: 25px;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* 商品詳細 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
.sec {
  margin-bottom: 35px;
  padding-bottom: 20px;
  border-bottom: dashed 2px #fbadad;
}
.sec.float {
  display: flex;
}
.sec.float p.image {
  flex-basis: 700px;
}
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* 最新記事*/
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.new-content,
.comment_box {
  margin: 0 0 10px;
  *margin: 0 0 5px;
  padding: 10px 10px 0px;
  font-size: 13px;
  line-height: 20px;
  text-align: left;
  color: #333;
  clear: both;
  overflow: auto;
}
.new-content ul {
  margin: 3px 0 10px;
  *margin: 2px 0 5px;
  padding: 0;
  font-size: 13px;
  line-height: 24px;
  text-align: left;
  list-style-type: none;
  border: none;
  background: none;
}
.new_day {
  width: 65px;
  padding: 2px 6px;
  color: #333;
}
.new-content ul li {
  height: 30px;
  margin: 0 0 3px;
  padding: 5px 0 2px 30px;
  color: #333;
  text-decoration: none;
  overflow: hidden;
  border-bottom: 1px #ccc dotted;
  background: url(../img/arrow.png) 13px center no-repeat;
}
.new-content ul li a {
  color: #ff5d95;
  font-weight: bold;
  margin: 3px 0 0 10px;
  padding: 0;
  line-height: 30px;
  text-decoration: underline;
}
.new-content ul li a:hover {
  color: #ff95ba;
}
.new_cate {
  font-size: 12px;
  padding: 2px 2px 0;
  font-weight: bold;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px;
  border-radius: 5px;
}
.new_cate {
  font-size: 2px;
  font-weight: bold;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* ソーシャルボタン設定 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

ul.social_button {
  margin: 0;
  padding: 5px 0 15px 0;
  clear: both;
}
ul.social_button li {
  margin: 0 5px 0 0;
  padding: 0;
  float: left;
  list-style: none;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* アイキャッチ画像設定 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.econtent img.attachment-200x200,
.econtent-none img.attachment-200x200 {
  float: left;
  margin: 0 20px 20px 0;
}
.econtent-i img.attachment-200x200,
.econtent-none-i img.attachment-200x200 {
  float: right;
  margin: 0 0 20px 20px;
}
.eye-catch-img {
  border: none;
  margin: 0;
  padding: 0;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* カテゴリー・アーカイブ */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.postform,
select {
  width: 100%;
  height: 40px;
  margin: -2px 0 20px;
  *margin: -1px 0 10px;
  padding: 10px;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* 続きを読む設定 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.link-next {
  color: #555;
  font-weight: bold;
  min-width: 100px;
  text-align: right;
  padding: 0 20px 10px 0;
  margin: 15px 0 10px;
  float: right;
  text-decoration: none;
}
.link-next a {
  color: #555;
  text-shadow: 0px 2px 2px #ccc;
  font-weight: bold;
  padding: 8px;
  text-decoration: none;
}
.link-next a:hover {
  color: #ff5d95;
  text-shadow: 0px 2px 2px #ff95ba;
  text-decoration: underline;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* 日付の設定 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#main_contents_box .date {
  height: 20px;
  margin: 0;
  padding: 0 0 5px;
  display: block;
  border-top: none;
  font-size: 12px;
  clear: both;
}
#main_contents_box .date p {
  height: 10px;
  font-weight: bold;
  text-align: right;
  font-size: 12px;
  line-height: 23px;
  float: right;
}
.left_time {
  width: auto;
  margin: 0 0 5px;
  padding: 3px;
  float: left;
}
.bb_time {
  font-size: 15px;
  font-weight: bold;
}
.date p {
  width: 50%;
  font-size: 13px;
  margin: 0 0 5px;
  text-align: right;
  float: right;
}
.no_day {
  width: 50%;
  margin: 0 0 5px;
  text-align: right;
  float: right;
}
.dnone {
  height: 9px;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.entry-content p.tag {
  font-size: 12px;
  text-align: right;
  margin: 10px 0 0;
  *margin: 5px 0 0;
  padding: 10px;
  border-bottom: 1px #ccc solid;
  border-top: 1px #ccc solid;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* blockquoteの設定 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

blockquote {
  background: #f7f7f7 url(../img/blockquote.png) no-repeat 0 0;
  padding: 15px;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  color: #212121;
  box-shadow: 1px 1px 2px rgba(204, 204, 204, 0.9),
    inset 0 1px 0 rgba(255, 255, 255, 0.4);
  clear: both;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* カテゴリ・タグの設定 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#main_contents_box .tag_etc {
  text-align: right;
  margin: 15px 0;
  *margin: 7px 0;
  padding: 5px 8px;
  border: 1px #fff solid;
  background: url(../img/dot_color.png) repeat;
  box-shadow: 0 0 1px #ff95ba, 0 0 1px #ff95ba;
  -webkit-box-shadow: 0 0 1px #ff95ba, 0 0 1px #ff95ba;
  clear: both;
}
#main_contents_box .tag_etc p {
  font-size: 11px;
}
#main_contents_box .tag_etc a {
  color: #444;
  text-decoration: none;
}
#main_contents_box .tag_etc a:hover {
  color: #f60;
  text-decoration: underline;
}
.cate_tag {
  padding-left: 16px;
  background: url(../img/tag.png) left center no-repeat;
}
.cate_cate {
  padding-left: 16px;
  background: url(../img/category.png) left center no-repeat;
}
.cate_comment {
  padding-left: 16px;
  background: url(../img/comment.png) left center no-repeat;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* ページナビ・個別記事ナビゲーション */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.tablenav,
.kiji-navigation {
  font-size: 13px;
  background: none;
  margin: 1em auto;
  line-height: 2em;
  text-align: center;
}
a.page-numbers,
.tablenav .current {
  color: #555;
  padding: 5px 10px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px;
  border-radius: 5px;
  box-shadow: 0 0 5px #ff95ba, 0 0 5px #fff;
  -webkit-box-shadow: 0 0 5px #ff95ba, 0 0 5px #fff;
  text-decoration: none;
  font-size: smaller;
  background: #fff;
}
.kiji-navigation a {
  color: #555555;
  text-decoration: none;
}
a.page-numbers:hover,
.kiji-navigation a:hover {
  color: #f60;
  background: #fff;
}
.tablenav .current {
  color: white;
  font-weight: bold;
  background: #ff95ba;
}
.tablenav .next,
.tablenav .prev {
  border: 0 none;
  background: #fff;
  text-decoration: underline;
  font-size: smaller;
  font-weight: bold;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* サイドカテゴリー・サイトマップ・カスタムメニュー設定 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#left_contents_box ul,
#right_contents_box ul {
  margin: -5px 0 10px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 17px;
  text-align: left;
  list-style: none;
}
#left_contents_box ul li,
#right_contents_box ul li {
  color: #111;
  margin: 0;
  padding: 10px 0;
  line-height: 200%;
  color: #333;
  text-decoration: none;
  border-top: none;
  border-bottom: 1px #28326d dotted;
}
#left_contents_box ul li a,
#right_contents_box ul li a {
  color: #111;
  padding: 0;
  margin: 0;
  color: #333;
  text-decoration: none;
  background: none;
  display: block;
}
#left_contents_box ul a:hover,
#right_contents_box ul li a:hover,
#left_contents_box ul li.last a:hover,
#right_contents_box ul li a:hover {
  color: #f60;
  text-decoration: none;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* サイド入れ子設定 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#left_contents_box ul.sub-menu,
#right_contents_box ul.sub-menu {
  margin: 15px 0 -10px;
  padding: 0;
  border: none;
}
#left_contents_box ul.sub-menu,
#left_contents_box ul.children,
#right_contents_box ul.sub-menu,
#right_contents_box ul.children {
  margin: 14px 0 -13px;
  padding: 0;
  color: #333;
  line-height: 140%;
  text-align: left;
}
#left_contents_box ul.sub-menu li,
#left_contents_box ul.children li,
#right_contents_box ul.sub-menu li,
#right_contents_box ul.children li {
  margin: 0;
  padding: 15px 0 15px 20px;
  color: #333;
  line-height: 140%;
  text-align: left;
  border-bottom: 1px #28326d dotted;
}
#left_contents_box ul.sub-menu li li,
#left_contents_box ul.children li li,
#right_contents_box ul.sub-menu li li,
#right_contents_box ul.children li li {
  margin: 0;
  padding: 15px 0 15px 20px;
  color: #333;
  line-height: 140%;
  text-align: left;
  border-bottom: 1px #28326d dotted;
}

/* コメント欄　━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#left_contents_box li.recentcomments,
#right_contents_box li.recentcomments {
  margin: 0;
  text-align: left;
}

/* 追尾型サイドバー */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#fix_box1,
#fix_box2 {
  min-width: 220px;
  max-width: 220px;
  width: 100%;
  margin: 0 0 20px;
  padding: 0;
  z-index: 9999;
}

/* アイキャッチ付き最新記事 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

ul#eye_news li a {
  margin: -5px 0;
  text-decoration: none;
  display: block;
  background: #ffffff;
}
#eye_news img {
  padding: 0;
  margin: 0 10px -2px 0;
  line-height: 100%;
}
#eye_news p {
  height: 55px;
  text-align: left;
  margin: -60px 0 0 60px;
  line-height: 150%;
  overflow: hidden;
}
#footer_box_matome ul#eye_news li {
  background: none;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* その他ウィジェット */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.textwidget {
  font-size: 12px;
  line-height: 150%;
  text-align: left;
  margin: 0 0 20px;
  padding: 0;
  clear: both;
}
.textwidget p {
  font-size: 12px;
  line-height: 160%;
  margin: 0;
  padding: 5px 0;
  border: 1px #fff solid;
  background-color: none;
  text-align: left;
  border: none;
  overflow: hidden;
}
.textwidget img {
  max-width: 100%;
  height: auto;
  overflow: hidden;
}
#main_contents_box .textwidget {
  margin: 0 0 20px;
  _margin: 0 0 10px;
  padding: 20px 20px;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #333;
  background: #fff url(../img/back1.png) bottom repeat-x;
  clear: both;
}
#main_contents_box .textwidget p {
  font-size: 14px;
  line-height: 160%;
  margin: 0;
  padding: 5px 0;
  text-align: left;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* メインコンテンツウィジェット */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.c_c_t_w .textwidget,
.c_c_b_w .textwidget {
  margin: 6px 0 20px;
  _margin: 3px 0 10px;
  padding: 5px 20px 10px;
  font-size: 13px;
  line-height: 20px;
  text-align: left;
  color: #333;
  background: #fff;
  clear: both;
  overflow: auto;
}
.c_c_t_w .textwidget,
.c_c_b_w .textwidget {
  margin: 6px 0 20px;
  _margin: 3px 0 10px;
  padding: 5px 20px 10px;
  font-size: 13px;
  line-height: 20px;
  text-align: left;
  color: #333;
  background: #fff;
  clear: both;
  overflow: auto;
}

.c_c_b_w .tagcloud p,
.c_c_t_w .tagcloud p {
  padding: 5px 0;
}
.c_c_t_w ul,
.c_c_b_w ul {
  margin: 6px 0 20px;
  padding: 0 20px;
  font-size: 13px;
  line-height: 20px;
  text-align: left;
  color: #333;
  background: #fff;
  clear: both;
  overflow: auto;
}
.c_c_t_w ul li,
.c_c_b_w ul li {
  margin: 0 0 10px;
  padding: 0;
  font-size: 13px;
  line-height: 24px;
  text-align: left;
  list-style-type: none;
  border: none;
  background: none;
  border-bottom: 1px #ccc dotted;
}
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.c_c_t_w ul li ul,
.c_c_b_w ul li ul {
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 24px;
  text-align: left;
  list-style-type: none;
  border: none;
  overflow: hidden;
  background: none;
}
.c_c_t_w ul li li,
.c_c_b_w ul li li {
  margin: 0 0 -10px;
  padding: 10px 0 10px 10px;
  font-size: 13px;
  line-height: 24px;
  text-align: left;
  list-style-type: none;
  border: none;
  background: none;
  border-top: 1px #ccc dotted;
}

/* リンク　━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.c_c_t_w ul li a,
.c_c_b_w ul li a {
  margin: 0;
  padding: 0 10px 0 0;
  color: #333;
  text-decoration: none;
  line-height: 160%;
  text-align: left;
}
.c_c_t_w a:hover,
.c_c_b_w a:hover {
  color: #f60;
  text-decoration: none;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* タグクラウド */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.tagcloud {
  font-size: 14px;
  line-height: 180%;
  text-align: center;
  margin: -9px 0 10px 0;
  padding: 10px 7px;
  background: none;
  clear: both;
  overflow: hidden;
}
.tagcloud a {
  color: #555;
  font-size: 18px;
  text-decoration: none;
}
.tagcloud a:hover {
  color: #f60;
  text-decoration: underline;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* カレンダー */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
#wp-calendar caption {
  margin: 0;
  padding: 12px 12px 7px;
  font-size: 15px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  line-height: 18px;
  background: url(../img/c_back.png) repeat;
  border-bottom: 5px #ff95ba solid;
  clear: both;
}
#wp-calendar {
  width: 220px;
  padding: 0;
  margin: 0 0 20px;
  color: #616b76;
  background: #fff;
  text-align: center;
}
/*曜日行*/
#wp-calendar thead {
  margin: 0;
  padding: 0;
  font-size: 12px;
}
/*曜日セル*/
#wp-calendar thead tr th {
  margin: 0;
  padding: 0;
  background: #e8e8e8;
  border-left: 1px #f2f2f2 solid;
  border-right: 1px #ddd solid;
}
/*日付セル*/
#wp-calendar tbody td {
  margin: 0;
  width: 24px;
  height: 24px;
  text-align: center;
}
#wp-calendar #prev a {
  color: #555;
  font-weight: bold;
  text-shadow: 0px 1px 1px #ccc;
  text-decoration: none;
}
#wp-calendar #prev a:hover {
  color: #ff5d95;
  text-shadow: 0px 1px 1px #ff95ba;
  text-decoration: underline;
}
/*当日*/
table#wp-calendar td#today {
  color: #fff;
  font-weight: bold;
  background: url(../img/c_back.png) repeat;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* 検索フォーム */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#searchform,
#main_contents_box #searchform {
  margin: 0 0 20px;
  padding: 0;
  background: #ff5d95;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px;
  border-radius: 5px;
  border: 1px #ff95ba solid;
}
#searchform .screen-reader-text {
  display: none;
}
/* 検索エリア */
#s {
  width: 60%;
  height: 32px;
  line-height: 30px;
  padding: 0 5%;
  margin: 0;
  vertical-align: bottom;
  border: none;
  border-radius: 5px 0 0 5px;
  outline: none;
  box-sizing: content-box;
  -webkit-appearance: textfield;
  color: #999;
  background: #fff;
}
#s:focus {
  color: #333;
}
/* 検索ボタン */
#searchsubmit {
  width: 30%;
  height: 32px;
  vertical-align: bottom;
  border: none;
  border-radius: 0 5px 5px 0;
  margin-left: -6px;
  color: #fff;
  *color: #333;
  background: -webkit-linear-gradient(top, #ff5d95, #ff95ba);
  background: -moz-linear-gradient(top, #ff5d95, #ff95ba);
  background: linear-gradient(top, #ff5d95, #ff95ba);
  cursor: pointer;
}
#searchsubmit:hover {
  background: -webkit-linear-gradient(top, #ff95ba, #ff5d95);
  background: -moz-linear-gradient(top, #ff95ba, #ff5d95);
  background: linear-gradient(top, #ff95ba, #ff5d95);
  border-left: 1px #ff95ba solid;
}
#searchsubmit:active {
  border-bottom-color: #fff;
  box-shadow: 0 1px 0 #ddd, 0 -1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #ddd;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* RSS購読設定 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.rss-space {
  text-align: center;
  margin: 0 0 20px;
  padding: 0;
  clear: both;
}
.rss-button {
  text-align: center;
  width: 100%;
  padding: 5px;
  clear: both;
}
.rss-space a {
  text-decoration: none;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* お気に入りボタン設定 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.favorite-space {
  text-align: center;
  margin: 0 auto 20px;
  padding: 0;
  clear: both;
}
.favorite-button {
  text-align: center;
  width: 100%;
  padding: 5px;
  clear: both;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* フッターBOX設定*/
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#footer_box {
  width: 100%;
  margin: 0;
  padding: 8px 0 0;
  font-size: 12px;
  clear: both;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* 固定ナビ */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
.fixed_nav {
  display: none;
  position: fixed;
  bottom: 260px;
  right: 5px;
  background: #fbadad;
  padding: 0.2em;
  cursor: pointer;
  z-index: inherit;
  color: #fff;
}
.fixed_nav_inner {
  display: flex;
  align-items: center;
}
.fixed_nav a {
  display: block;
  padding: 0.5em 1em;
  text-decoration: none;
  font-weight: 900;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .fixed_nav {
    display: none;
    position: fixed;
    bottom: 25vw;
    right: 0;
    background: #fbadad;
    padding: 0.2em;
    cursor: pointer;
    z-index: inherit;
    color: #fff;
  }
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* TOPに戻るボタン */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#go_top {
  width: 970px;
  margin: 0 auto;
  padding: 0;
  background: none;
  display: block;
  text-align: right;
  clear: both;
  overflow: hidden;
  position: fixed;
  bottom: 20px;
  right: 20px;
}
#go_top a {
  width: 50px;
  height: 50px;
  display: block;
  margin: 0;
  padding: 0;
  text-decoration: none;
  background: url(../img/go_top2.png) center no-repeat;
  float: right;
}
#go_top a:hover {
  text-decoration: none;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* フッターフリースペース */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#f_box_matome {
  width: 970px;
  margin: -20px auto 0;
  padding: 0 0.5%;

  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  -moz-border-radius-bottomleft: 6px;
  -moz-border-radius-bottomright: 6px;
  overflow: auto;
  *overflow: hidden;
  clear: both;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.mini_box {
  width: 31.3%;
  margin: 10px 0.5% 0;
  color: #555;
  text-align: left;
  display: block;
  padding: 0.5%;
  background: #ffffff;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  float: left;
}
.mini_box .title {
  margin: 0 0 8px;
  padding: 12px 12px 14px;
  font-size: 15px;
  font-weight: bold;
  text-shadow: 1px 1px 0px #ccc;
  text-align: left;
  line-height: 18px;
  border: none;
  box-shadow: none;

  clear: both;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.mini_box ul {
  margin: 0 0 10px;
  padding: 0;
  list-style: inside;
  text-align: left;
  text-decoration: none;
}
.mini_box ul li {
  padding: 5px 0 5px 20px;
  margin: 0;
  text-align: left;
  list-style: none;
  border-bottom: 1px #ccc dotted;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* フッターカテゴリー・サイトマップ・カスタムメニュー設定 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.mini_box ul.sub-menu {
  margin: 6px 0 -5px;
  padding: 0;
  border: none;
}
.mini_box ul.children {
  margin: 6px 0 -5px;
  padding: 0;
  color: #333;
  line-height: 140%;
  text-align: left;
}
.mini_box ul.sub-menu li,
.mini_box ul.children li {
  margin: 0;
  padding: 6px 0 6px 20px;
  color: #333;
  line-height: 140%;
  text-align: left;
  border: none;
  border-top: 1px #ccc dotted;
}
.mini_box ul.sub-menu li li,
.mini_box ul.children li li li {
  margin: 0;
  padding: 6px 0 6px 20px;
  color: #333;
  line-height: 140%;
  text-align: left;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.mini_box ul#eye_news img {
  margin: 3px 0 6px -10px;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.mini_box .tagcloud {
  line-height: 180%;
  text-align: center;
  margin: 0;
  padding: 10px;
  clear: both;
  overflow: hidden;
  border: none;
  background: #fff;
  box-shadow: none;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.mini_box .textwidget_border0,
.mini_box .textwidget {
  background: #ffffff;
  padding: 10px;
  border: none;
  box-shadow: none;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.mini_box #wp-calendar {
  margin: auto auto 10px;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.mini_box a {
  color: #555;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
}
.mini_box a:hover {
  color: #f60;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* コピーライト部分設定 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#footer {
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  clear: both;
}
#footer p {
  width: 950px;
  height: 37px;
  margin: 0 auto;
  padding: 0;
  color: #000000;
  line-height: 37px;
  font-size: 13px;
  overflow: hidden;
}
#footer a {
  color: #000000;
  text-decoration: none;
}
#footer a:hover {
  color: #f60;
  text-decoration: none;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/*関連記事ページ設定*/
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.kanrenmenu_thumb,
.Kanrenmenu {
  margin: 0px 0 20px;
  _margin: 0 0 10px;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #333;
  border: none;
  overflow: hidden;
  clear: both;
}
.kanrenmenu_thumb ul,
.Kanrenmenu ul {
  margin: 0;
  padding: 10px 0;
  list-style: none;
  clear: both;
}
.kanrenmenu_thumb ul li,
.Kanrenmenu ul li {
  margin: 0;
  padding: 10px 0;
  color: #06f;
  text-decoration: none;
  text-align: left;
  background: none;
  border-bottom: 1px #cccccc dotted;
  overflow: auto;
  clear: both;
}
.kanrenmenu_thumb ul li img {
  padding: 1px;
  margin: 3px 0 3px 10px;
  border: 1px #cccccc solid;
  float: left;
}
.kt-tag {
  margin: 0;
  padding: 0 10px 0 20px;
  color: #444;
  text-decoration: none;
  overflow: hidden;
}
.kt-tag p.r-time-data {
  font-size: 13px;
  text-align: left;
  line-height: 120%;
  margin-bottom: 10px;
}
.kt-tag p.r-title-l {
  text-align: left;
  line-height: 130%;
}
.kt-tag a {
  color: #000000;
  font-size: 14px;
  text-decoration: none;
}
.kt-tag a:hover {
  color: #f60;
  text-decoration: none;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* コメント */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.comments-box {
  zoom: 1;
  margin: 0;
}
.comments-box img {
  vertical-align: middle;
}
.cancel-comment-reply {
  padding: 5px 0 0;
}
.cancel-comment-reply a {
  color: #000;
}
.text {
  border: 1px solid #ccc;
  padding: 2px;
  color: #777;
  width: 200px;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* コメントフレーム部分 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

ol.commentlist a:link {
  text-decoration: none;
}
ol.commentlist li div.vcard cite.fn a.url:hover {
  text-decoration: none;
}
ol.commentlist {
  margin: 10px 0 0 0;
  padding: 0;
  background: #fff;
}
ol.commentlist li {
  margin: 10px 0 10px 0;
  padding: 10px;
  list-style-type: none;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px #c5c5c5, 0 0 5px #fff;
  -webkit-box-shadow: 0 0 5px #c5c5c5, 0 0 5px #fff;
}
ol.commentlist li div.vcard cite.fn {
  font-style: normal;
  font-weight: bold;
}
ol.commentlist li div.vcard cite.fn a.url {
  color: #95aac5;
}
ol.commentlist li div.vcard img.avatar-32 {
  padding: 2px;
  background: #fff;
  border: 1px solid #ccc;
}
ol.commentlist li div.vcard img.photo {
  padding: 2px;
  background: #fff;
  border: 1px solid #ccc;
}
ol.commentlist li div.vcard span.says {
  color: #888;
}
ol.commentlist li div.comment-meta {
  margin: 3px 0 0 0;
  font-size: 0.9em;
}
ol.commentlist li div.comment-meta a {
  color: #aaa;
  text-decoration: none;
}
ol.commentlist li p {
  margin: 15px 0 0 0;
  line-height: 1.4em;
}
ol.commentlist li div.reply {
  background: #f8f8f8;
  color: #333;
  width: 33px;
  margin: 10px 0 0 0;
  padding: 2px 5px 2px 5px;
  line-height: 16px;
  border: 1px solid #e9e9e9;
}
ol.commentlist li div.reply a {
  color: #333 !important;
}
ol.commentlist li div.reply a:hover {
  color: red !important;
  text-decoration: none;
}
ol.commentlist li ul.children li {
  margin: 10px 0 0 0;
  background: #fff;
  border: 1px solid #ccc;
}
ol.commentlist li ul.children li li.alt {
  background: #fff;
}
ol.commentlist li ul.children li.depth-2 {
  background: #eee;
}
ol.commentlist li ul.children li.depth-3 {
  background: #ddd;
}
ol.commentlist li ul.children li.depth-4 {
  background: #eee;
}
ol.commentlist li ul.children li.depth-5 {
  background: #fafafa;
}
ol.commentlist li ul.children li.depth-6 {
  background: #fafafa;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* コメントフォーム */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

input[type="submit"] {
  color: #333;
}
#respond {
  background: #fff;
  margin: 0;
  padding: 10px;
  position: static;
  border: 1px solid #ddd;
}
#container2l #respond,
#container2l #respond {
  background: #eee;
  margin: 0;
  padding: 10px;
  position: static;
  width: 670px;
}
#respond input[type="text"] {
  display: block;
  height: 24px;
}
#respond input[type="text"],
#respond textarea {
  background: white;
  border: 1px solid #ccc;
  padding: 5px;
  font-size: 15px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
}
#respond input#submit {
  cursor: pointer;
  font-size: 15px;
  margin: 7px auto 0;
  padding: 5px 20px;
  position: relative;
  left: auto;
  border: 1px solid #ccc;
  color: #555;
  background: -webkit-linear-gradient(top, #fff, #eee);
  background: -moz-linear-gradient(top, #fff, #eee);
  background: linear-gradient(top, #fff, #eee);
}
#contents .box_out {
  background-color: fafafa;
  margin-bottom: 5px;
  padding: 5px;
}
#contents .box_in {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  padding: 10px;
}
#fs {
  margin: 0px;
}
#comform input,
#tb input,
#comform select,
#comform textarea {
  padding: 0.5em;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* トラックバック */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#tb {
  width: 100%;
  margin: 10px 0;
  padding: 10px 0 0;
}
.trackback-content {
  margin-top: 10px;
}
ul#trackback-list {
  font-weight: normal;
  margin: 0 20px;
  padding: 0;
  background-color: #ffffff;
  border: none;
}
#trackback-list li {
  list-style: none;
  font-weight: normal;
  margin: 0 0 10px;
  padding: 0;
  background-color: #ffffff;
  border: none;
}
#trackback-list p.comment-title {
  font-size: 14px;
  margin: 20px 0px 10px;
}
#trackback-list p.comment-data {
  text-align: right;
  font-size: 10px;
  margin: 0 0 10px;
  padding: 10px 10px 5px 0px;
  border-bottom: 1px dotted #000033;
}
#trackback-list p {
  font-size: 14px;
  margin: 0px 10px 15px;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* 以下その他のページ設定 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* コメント設定*/
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.comment_box p {
  line-height: 20px;
  text-align: left;
  color: #222;
}
.comment_write {
  text-align: left;
}
.abox {
  margin: 0;
  line-height: 20px;
  text-align: left;
  color: #222;
}
.bbox {
  margin: 0 0 10px 20px;
  line-height: 20px;
  text-align: left;
  color: #222;
}

/* サイトマップページ・ アーカイブページ設定 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#econtent-none .sitemap ul {
  font-size: 14px;
  margin: 10px 0 0;
  padding: 0;
  list-style: none;
}
.sitemap li {
  font-size: 14px;
  margin: 0 10px 10px;
  padding: 0 0 0 25px;
  list-style: none;
}
.sitemap li a {
  text-decoration: none;
  color: #555;
}
.sitemap li a:hover {
  color: #f60;
  text-decoration: underline;
}
h4.sitemap-cate-title {
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  margin: 2px 10px 10px;
  padding: 8px 15px 6px 10px;
  background: #fff;
  clear: both;
}
h4 a {
  text-decoration: none;
  color: #555;
}
h4 a:hover {
  color: #f60;
  text-decoration: underline;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* ショートコード
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.docter_man,
.docter_woman,
.nurse,
.man,
.old_man,
.woman,
.old_woman {
  float: left;
}

/* 大枠フキダシ */
.fkdsbox_black,
.fkdsbox_blue,
.fkdsbox_green,
.fkdsbox_red,
.fkdsbox_pink,
.fkdsbox_orange {
  max-width: 60%;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* ランキング用ボタン
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

/* ランキング用ボタン１ */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
.go_ranking {
  margin: 20px 0 30px;
  text-align: right;
  display: flex;
}
#contents_in .go_ranking img {
  width: 338px;
  margin: 0;
}
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* グラフ設定
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

/* グラフ１ */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

table.graph {
  width: 100%;
  border-top: none;
  border-collapse: collapse;
}
table.graph tr {
  margin: 0;
  padding: 0;
  border: 1px solid #dddccc;
}
table.graph th {
  margin: 0;
  padding: 7px 10px;

  border-left: 1px solid #dddccc;
  border-right: 1px solid #dddccc;
  width: 200px;
}
table.graph td {
  margin: 0;
  padding: 7px 10px;
  background: #ffffff;
  border: 1px solid #dddccc;
}

/* グラフ２ */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

table.graph2 {
  width: 100%;
  border-collapse: collapse;
}
table.graph2 tr {
  margin: 0;
  padding: 0;
}
table.graph2 th {
  margin: 0;
  padding: 7px 10px;
  border-style: solid;
  border-width: 1px;
}
table.graph2 td {
  margin: 0;
  padding: 7px 10px;
  background: #ffffff;
  border-style: solid;
  border-width: 1px;
}

/* グラフ３ */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

table.graph3 {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
table.graph3 tr {
  margin: 0;
  padding: 0;
  border: none;
}
table.graph3 th {
  color: #fff;
  padding: 8px 15px;
  background: #258;
  background: -moz-linear-gradient(
    rgba(34, 85, 136, 0.7),
    rgba(34, 85, 136, 0.9) 50%
  );
  background: -webkit-gradient(
    linear,
    100% 0%,
    100% 50%,
    from(rgba(34, 85, 136, 0.7)),
    to(rgba(34, 85, 136, 0.9))
  );
  font-weight: bold;
  border-left: 1px solid #258;
  border-top: 1px solid #258;
  border-bottom: 1px solid #258;
  line-height: 120%;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(34, 85, 136, 0.9);
  box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.3) inset;
}
table.graph3 th:first-child {
  border-radius: 5px 0 0 0;
}
table.graph3 th:last-child {
  border-radius: 0 5px 0 0;
  border-right: 1px solid #258;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1),
    0px 1px 1px rgba(255, 255, 255, 0.3) inset;
}
table.graph3 td {
  margin: 0;
  padding: 7px 10px;
  background: #ffffff;
}

/* グラフ4 */
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

table.graph4 {
  table-layout: fixed;
  width: 100%;
  border-top: none;
  border-collapse: collapse;
}
table.graph4 tr {
  margin: 0;
  padding: 0;
  border: 1px solid #dddccc;
}
table.graph4 th {
  margin: 0;
  padding: 7px 2px;

  border-left: 1px solid #dddccc;
  border-right: 1px solid #dddccc;
  text-align: center;
  width: 100px;
}
table.graph4 td {
  margin: 0;
  padding: 12px 2px;
  border: 1px solid #dddccc;
  text-align: center;
  font-size: 12px;
  width: 100px;
}
@media screen and (max-width: 768px) {
  table.graph4 td {
    width: calc((90vw - 100px) / 3);
  }
}
table.graph4 td a:hover {
  opacity: 0.5;
}
table.graph4 td p {
  font-size: 12px;
  text-align: center;
  padding-top: 3px;
}

table.graph4 tbody {
  table-layout: fixed;
  width: 100%;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* ランキング用ボックス
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.product_box {
  border-top: none;
  margin: 0;
  padding: 10px 5px 0;
}
.product_box p {
  font-size: 14px;
  line-height: 160%;
}
.product_box a {
  text-decoration: underline;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#contents_in .textwidget table {
  font-size: 14px;
  line-height: 160%;
}
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* ランキングa
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.rankinga-border {
  border-style: solid;
  border-width: 3px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -khtml-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px 15px;
}
.rankingvera {
  width: 100%;
  border: none;
  border-collapse: collapse;
  margin: 0 0 10px;
}
.rankingvera tr {
  margin: 0;
}
.rankingvera .r1-title-no1 {
  font-size: 18px;
  font-weight: bold;
  color: #363434;
  line-height: 220%;
  text-align: left;
  margin: 0 0 10px 0;
  padding: 5px 10px 2px 64px;
  background: url(../img/1.png) no-repeat left center;
}
.rankingvera .r1-title-no2 {
  font-size: 18px;
  font-weight: bold;
  color: #363434;
  line-height: 220%;
  text-align: left;
  margin: 0 0 10px 0;
  padding: 5px 10px 2px 64px;
  background: url(../img/silver.gif) no-repeat left center;
}
.rankingvera .r1-title-no3 {
  font-size: 18px;
  font-weight: bold;
  color: #363434;
  line-height: 220%;
  text-align: left;
  margin: 0 0 10px 0;
  padding: 5px 10px 2px 64px;
  background: url(../img/blonds.gif) no-repeat left center;
}
.rankingvera .r1-title-no4 {
  font-size: 18px;
  font-weight: bold;
  color: #363434;
  line-height: 220%;
  text-align: left;
  margin: 0 0 10px 0;
  padding: 5px 10px 2px 64px;
  background: url(../img/back.png) no-repeat left center;
}
.rankingvera .r1-title-no5 {
  font-size: 18px;
  font-weight: bold;
  color: #363434;
  line-height: 220%;
  text-align: left;
  margin: 0 0 10px 0;
  padding: 5px 10px 2px 64px;
  background: url(../img/back.png) no-repeat left center;
}
.rankingvera td {
  width: 30%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  background-color: none;
}
.rankingvera td p {
  font-size: 14px;
  line-height: 160%;
}
#contents_in .rankingvera p.catch {
  font-size: 20px !important;
  font-weight: bold;
  color: #d80000;
  margin: 5px 0 10px;
}
.rankingvera td a {
  text-decoration: underline;
}
.rankingvera td.rbg {
  width: 96%;
  *width: 68%;
  display: block;
  margin: 0 3% 0 0;
  *margin: 0;
  padding: 0;
  background: url(../img/back.png) no-repeat;
  float: left;
}
.rankingvera .ra-img {
  width: 100%;
  margin: 40px 0 0 4%;
  padding: 0;
  float: right;
}
.rankingvera .ra-img a img {
  width: 100%;
  *width: 90%;
  margin: 0;
  padding: 0;
}
.rankingvera .r-a-contents {
  margin: 0 0 15px;
  padding: 5px 10px;
  background: url(../img/ranking/orange_moji_back.png) repeat;
  border: 3px #ffe49a solid;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -khtml-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* ランキングb
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.rankingb-box {
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.rankingverb {
  width: 100%;
  padding: 0;
  margin: 0;
  border-collapse: collapse;
}
.rankingverb tr {
  width: 100%;
  margin: 0;
  border-style: 1px solid #dddccc;
}

.rankingverd tr {
  width: 100%;
  margin: 0;
  border-style: 1px solid #dddccc;
}

.rankingverb th {
  padding: 0px 10px 0px 5px;
  /**/
  /*border: 1px solid #DDDCCC;*/
  background: url(../img/back2.png) left top repeat-y;
}

.rankingverd th {
  padding: 10px 0 10px 0px;
  border-bottom: 1px dotted #fcaeae;
  text-align: center;
}

.rankingverd .r1-title-no1 {
  font-size: 16px;
  color: #363434;
  line-height: 140%;
  text-align: left;
  margin: 0 0 6px 0;
  padding: 15px 10px 8px 40px;
  background: url(../img/1.png) no-repeat 0px center;
}
.rankingverd .r1-title-no2 {
  font-size: 16px;
  color: #363434;
  line-height: 140%;
  text-align: left;
  margin: 0 0 6px 0;
  padding: 15px 10px 8px 40px;
  background: #ccc;
  background: url(../img/2.png) no-repeat 0px center;
}

.rankingverd .r1-title-no3 {
  font-size: 16px;
  color: #363434;
  line-height: 140%;
  text-align: left;
  margin: 0 0 6px 0;
  padding: 15px 10px 8px 40px;
  background: #ccc;
  background: url(../img/3.png) no-repeat 0px center;
}

.rankingverd .r1-title-no4 {
  font-size: 16px;
  color: #363434;
  line-height: 140%;
  text-align: left;
  margin: 0 0 6px 0;
  padding: 15px 10px 8px 40px;
  background: #ccc;
  background: url(../img/4.png) no-repeat 0px center;
}

.rankingverd .r1-title-no5 {
  font-size: 16px;
  color: #363434;
  line-height: 140%;
  text-align: left;
  margin: 0 0 6px 0;
  padding: 15px 10px 8px 40px;
  background: #ccc;
  background: url(../img/5.png) no-repeat 0px center;
}

.rankingverb .r1-title-no1 {
  font-size: 24px;
  color: #504233;
  line-height: 140%;
  text-align: left;
  margin: 0;
  padding: 15px 10px 8px 50px;
  background: url(../img/1.png) no-repeat left center;
}

.rankingverb .r1-title-no2 {
  font-size: 24px;
  color: #504233;
  line-height: 140%;
  text-align: left;
  margin: 0;
  padding: 15px 10px 8px 50px;
  background: url(../img/2.png) no-repeat left center;
}
.rankingverb .r1-title-no3 {
  font-size: 24px;
  color: #504233;
  line-height: 140%;
  text-align: left;
  margin: 0;
  padding: 15px 10px 8px 50px;
  background: url(../img/3.png) no-repeat left center;
}
.rankingverb .r1-title-no4 {
  font-size: 24px;
  color: #504233;
  line-height: 140%;
  text-align: left;
  margin: 0;
  padding: 15px 10px 8px 50px;
  background: url(../img/4.png) no-repeat left center;
}
.rankingverb .r1-title-no5 {
  font-size: 24px;
  color: #504233;
  line-height: 140%;
  text-align: left;
  margin: 0;
  padding: 15px 10px 8px 50px;
  background: url(../img/5.png) no-repeat left center;
}
.rankingverb td {
  width: 30%;
  margin: 0;
  padding: 20px 10px 10px 10px;
  border-collapse: collapse;
  background-color: none;
  float: left;
}
.rankingverb .rb-img {
  width: 100%;
  margin: 0;
  padding: 0;
}
.rankingverb td .ra-img a img {
  width: 100%;
}
.rankingverb td.rbg {
  width: 65%;
  display: block;
  margin: 0;
  padding: 20px 0 10px 0;
  float: right;
}
.rankingverb td p {
  max-width: 100%;
  margin: 0;
  font-size: 15px;
  line-height: 160%;
}
#contents_in .rankingverb td p.catch {
  max-width: 100%;
  margin: 5px 0 10px;
  font-size: 20px;
  font-weight: bold;
  color: #d80000;
}
.rankingverb td a {
  text-decoration: underline;
}
.rankingverb .product_detail_list {
  list-style: none;
  padding-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .rankingverb .product_detail {
    display: flex;
    flex-direction: column;
  }
}
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* ランキングc
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.rankingc-box {
  width: 98%;
  border-bottom: 3px dotted #cccddd;
  margin: 0 0 10px;
  padding: 10px 0;
}
.rankingverc {
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
}
.rankingverc tr {
  margin: 0;
  padding: 0;
}
.rankingverc .title-back {
  margin: 0 0 15px;
  padding: 0;
}
.rankingverc .r1-title-no1 {
  font-size: 16px;
  font-weight: bold;
  color: #009966;
  line-height: 140%;
  text-align: left;
  margin: 0;
  padding: 15px 10px 8px 50px;
  background: url(../img/ranking/d-rank1.png) no-repeat 10px center;
}
.rankingverc .r1-title-no2 {
  font-size: 16px;
  font-weight: bold;
  color: #363434;
  line-height: 140%;
  text-align: left;
  margin: 0;
  padding: 15px 10px 8px 50px;
  background: url(../img/ranking/d-rank2.png) no-repeat 10px center;
}
.rankingverc .r1-title-no3 {
  font-size: 16px;
  font-weight: bold;
  color: #363434;
  line-height: 140%;
  text-align: left;
  margin: 0;
  padding: 15px 10px 8px 50px;
  background: url(../img/ranking/d-rank3.png) no-repeat 10px center;
}
.rankingverc .r1-title-no4 {
  font-size: 16px;
  font-weight: bold;
  color: #363434;
  line-height: 140%;
  text-align: left;
  margin: 0;
  padding: 15px 10px 8px 50px;
  background: url(../img/ranking/d-rank4.png) no-repeat 10px center;
}
.rankingverc .r1-title-no5 {
  font-size: 16px;
  font-weight: bold;
  color: #363434;
  line-height: 140%;
  text-align: left;
  margin: 0;
  padding: 15px 10px 8px 50px;
  background: url(../img/ranking/d-rank5.png) no-repeat 10px center;
}
.rankingverc td.c_photo {
  width: 30%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
}
.rankingverc .rc-img {
  width: 100%;
  *width: 80%;
  position: relative;
  margin: 0 0 10px;
  padding: 10px 5%;
  border-top: 1px solid #dddccc;
  border-right: 1px solid #dddccc;
  border-bottom: 2px solid #dddccc;
  border-left: 1px solid #dddccc;
  background: #fff;
}
.rankingverc .rc-img a img {
  width: 100%;
}
.rankingverc .seal {
  width: 35%;
  height: 25px;
  position: absolute;
  top: -12px;
  left: 35%;
}
.rankingverc td.rbg {
  width: 92%;
  display: block;
  margin: 0;
  padding: 10px 0 10px 8%;
  float: right;
}
.rankingverc td p {
  font-size: 14px;
  line-height: 160%;
}
#contents_in .rankingverc p.catch {
  max-width: 100%;
  font-size: 14px;
  font-weight: bold;
  color: #ff0000;
  margin: 10px 0 0;
  padding: none;
}
.rankingverc td a {
  text-decoration: underline;
}

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
/* ランキングd
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.rankingd-box {
  margin: 0;
  padding: 0;
}
.box_in_ranking_black {
  width: 30%;
  float: left;
  padding: 5px 0.5% 10px;
  margin: 0 0.5%;
  border-color: #dddccc;
  border-style: solid;
  border-width: 1px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px;
  border-radius: 5px;
}
.rankingd-box .r1-title-no1 {
  font-size: 14px;
  font-weight: bold;
  color: #363434;
  line-height: 140%;
  text-align: left;
  margin: 0 0 5px;
  padding: 15px 10px 8px 50px;
  background: url(../img/ranking/c-rank1.png) no-repeat left center;
}
.rankingd-box .r1-title-no2 {
  font-size: 14px;
  font-weight: bold;
  color: #363434;
  line-height: 140%;
  text-align: left;
  margin: 0 0 5px;
  padding: 15px 10px 8px 50px;
  background: url(../img/ranking/c-rank2.png) no-repeat left center;
}
.rankingd-box .r1-title-no3 {
  font-size: 14px;
  font-weight: bold;
  color: #363434;
  line-height: 140%;
  text-align: left;
  margin: 0 0 5px;
  padding: 15px 10px 8px 50px;
  background: url(../img/ranking/c-rank3.png) no-repeat left center;
}

.box_in_ranking_black a {
  text-decoration: none;
  background: none;
}
#contents_in .box_in_ranking_black p {
  font-size: 13px;
  padding: 2px 0 5px;
  clear: both;
}
#contents_in .box_in_ranking_black p.catch {
  font-size: 14px;
  font-weight: bold;
  color: #ff0000;
  padding: 10px 0 5px;
}
#contents_in .box_in_ranking_black p.r-link {
  padding: 5px 0;
  text-align: right;
}
#contents_in .box_in_ranking_black p.r-link a {
  color: #0044cc;
  font-weight: bold;
  text-decoration: underline;
}
#contents_in .box_in_ranking_black p.r-link a:hover {
  color: #f60;
  text-decoration: none;
}
.box_in_ranking_black img {
  max-width: 100%;
  *width: 95%;
  margin: 0;
  *margin: 0 auto;
  padding: 0;
  float: left;
}
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━

 ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

body {
  background-size: 100% 580px;
}
h2 {
  height: 40px;
  line-height: 42px;
  font-size: 20px;
  padding: 10px 15px 10px 65px;
  background-size: 44px 42px;
}
h2 a {
  color: #333333;
}
#blog_explain_box {
  color: #444444;
}
#main_box3,
#main_box2l,
#main_box2r,
#main_box,
#main_boxk3,
#main_boxk2l,
#main_boxk2r,
#main_box1,
#main_boxk1 {
  min-height: 400px;
}

#blog_explain_box {
  height: 380px;
  margin: -400px 0 0 10px;
  *margin: -400px 0 0 -250px;
}

.pcnon {
  display: none;
}
.pcnon2 {
  display: block !important;
}
.spnon2 {
  display: none !important;
}
@media screen and (max-width: 768px) {
  .spnon2 {
    display: block !important;
  }
  .pcnon2 {
    display: none !important;
  }
} /**/
@media screen and (max-width: 768px) {
  body {
    background-size: 100% 522px;
  }
  #main_banner {
    width: 96%;
    height: auto;
  }
  #main_banner img {
    width: 96%;
    height: auto;
  }
  #blog_explain_box {
    height: auto;
    margin: 0;
  }
}
@media screen and (max-width: 480px) {
  h2 {
    height: 34px;
    font-size: 17px;
    padding: 9px 15px 9px 65px;
    background-size: 38px 36px;
  }
  .spnon {
    display: none;
  }
  .pcnon {
    display: block;
  }
}
.viral li {
  margin-bottom: 20px;
  padding: 40px 20px;
  border: #ccc solid 1px;
  list-style-type: none;
}
.top_viral li {
  border-top: 1px solid #ccc;
  list-style-type: none;
  padding: 40px 20px;
}
.merit {
  margin-top: 40px;
}
.merit li {
  padding-left: 100px;
  background: #e6e6ff
    url(https://allergy-allergy.com/wp-content/uploads/2014/12/merit.png) left
    no-repeat;
}
.demerit {
  margin-top: 40px;
}
.top_viral .demerit {
  margin-top: 0px;
}
.top_viral .demerit li {
  padding-left: 100px;
  background: #fff
    url(https://allergy-allergy.com/wp-content/uploads/2014/12/demerit.png) left
    no-repeat;
}
.demerit li {
  padding-left: 100px;
  background: #fedee9
    url(https://allergy-allergy.com/wp-content/uploads/2014/12/demerit.png) left
    no-repeat;
}
.viral .comment {
  margin-top: -20px;
  margin-left: 10px;
  padding: 20px;
  border: #ccc solid 1px;
  border-top: none;
}
.reputation .reputation_title {
  padding: 0.5em;
  background-color: #ff95ba;
  color: #fff;
  font-weight: bold;
}
.reputation .reputation_title-dark {
  padding: 0.5em;
  background-color: #354dbb;
  color: #fff;
  font-weight: bold;
}
.reputation .reputation_item {
  display: inline-block;
  max-width: 80%;
  padding: 0;
  vertical-align: top;
}
.reputation .reputation_item li {
  background: none;
  padding-left: 10px;
}
.reputation .reputation_item::after {
  content: "";
  clear: both;
  display: block;
}
.reputation .reputation_image {
  display: inline-block;
  margin-right: 1rem;
  padding-left: 10px;
  vertical-align: top;
}
@media screen and (max-width: 768px) {
  .reputation .reputation_item {
    display: block;
    max-width: 100%;
    padding: 0;
  }
  .reputation .reputation_image {
    float: left;
    display: block;
    margin-right: 1rem;
    padding-left: 10px;
  }
}
.osusume {
  background: #fffad1;
  padding: 6px 10px 2px;
  display: inline-block;
}
.sr {
  background: #fbadad;
  padding: 6px 10px 6px;
  display: inline-block;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}
.sr span {
  display: inline-block;
  padding-top: 20px;
  vertical-align: top;
  padding-left: 6px;
}
.go_ranking a:hover img {
  opacity: 0.6;
  filter: alpha(opacity=60);
  -ms-filter: "alpha( opacity=60 )";
  -moz-opacity: 0.6;
  -khtml-opacity: 0.6;
}
#night_bn {
  background: #ffffff none repeat scroll 0 0;
  bottom: 4px;
  position: fixed;
  right: 4px;
  z-index: 10;
}
#night_bn_sp {
  display: none;
}
.pc {
  display: block;
}
.sp {
  display: none;
}
@media screen and (max-width: 768px) {
  .pc {
    display: none;
  }
  .sp {
    display: block;
  }
  .go_ranking {
    margin: 20px 0 30px;
    display: block;
  }
  .go_ranking img {
    display: block;
    width: 100% !important;
    text-align: center;
    margin: 0 auto 20px auto !important;
  }
  #night_bn {
    display: none;
  }
  #night_bn_sp {
    display: block;
    width: 100%;
    bottom: 0px;
    position: fixed;
    z-index: 100;
  }
  #night_bn_sp img {
    width: 100%;
  }
}
.m_y {
  background: linear-gradient(transparent 60%, #ffff66 60%);
}
#main_banner2 {
  display: block;
  height: 510px;
  width: 100%;
  background: url(../img/main_bg2.jpg) no-repeat center 0;
}
.tap1 {
  font-size: 140%;
  font-weight: bold;
  color: #d80000;
  padding: 20px 0 40px 0;
}
.tap2 {
  font-size: 140%;
  font-weight: bold;
  color: #d80000;
  padding: 10px 0;
}
.tap_box {
  padding: 20px;
  background: #fef8f8;
  margin-top: 20px;
}
.tap_box p {
  font-size: 16px;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  #main_banner2 {
    width: 96%;
    height: auto;
  }
  #main_banner2 img {
    width: 96%;
    height: auto;
  }
} /**/

#m_banner {
}
@media screen and (max-width: 1100px) {
  #m_banner .sp img {
    max-width: 100%;
  }
}
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  top banners to generation
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
.genaration_banners_wrapper {
  display: block;
  margin-top: 50px;
  padding: 1rem;
  background-color: #fef8f8;
}
.generation_banners_title {
  margin-bottom: 0.4rem;
  color: #555;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}
.generation_banners {
  justify-content: center;
  list-style: none;
}
.generation_banner {
  width: 100%;
  max-width: 190px;
  background-color: #fff;
  border: 3px solid #d10167;
  border-radius: 6px;
  transition: all 0.7s;
}
.generation_banner + .generation_banner {
  margin-left: 2rem;
}
.generation_banner a {
  text-decoration: none;
}
.generation_banner:hover {
  opacity: 0.7;
}
.generation_banner .text {
  width: 100%;
  margin: 1rem 0;
  padding: 0;
  border: none;
  color: #333;
  line-height: 1.3;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}
.generation_banner img {
  width: 100%;
  max-height: 120px;
  object-fit: cover;
}
.generation_banner .button {
  display: block;
  background-color: #09a715;
  padding: 0.4em;
  margin: 0.4em;
  color: #fff;
  font-size: 1rem;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .generation_banners {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .generation_banner {
    width: 46%;
    margin-bottom: 1em;
  }
  .generation_banner + .generation_banner {
    margin-left: 0;
  }
  .generation_banner img {
    max-height: 100px;
  }
  .generation_banner .text {
    margin: 0.5rem 0;
    line-height: 1.2;
    font-size: 1rem;
  }
  .generation_banner .button {
    font-size: 0.9rem;
  }
}
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  page_generation
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
.page_generation h3 {
  margin: 0;
  padding: 0;
  text-shadow: none;
  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: bold;
  background: none;
  clear: both;
}
.page_generation .module {
  margin-bottom: 3rem;
}
.page_generation .fv {
  margin-bottom: 1rem;
}
.page_generation .arrow {
  display: block;
  margin: 2rem auto;
}
/* page_generation lead */
.page_generation .lead_title {
  background-color: #fcaeae;
  padding: 0.3rem 0 0;
  color: #fff;
  font-weight: bold;
  border-radius: 4px 4px 0 0;
}
.page_generation .lead_title-dark {
  background-color: #354dbb;
  padding: 0.3rem 0 0;
  color: #fff;
  font-weight: bold;
  border-radius: 4px 4px 0 0;
}
.page_generation .lead_box {
  background-color: #fef8f8;
  padding: 1.2rem;
  border: 2px solid #fcaeae;
  border-radius: 0 0 4px 4px;
}
.page_generation .lead_box-dark {
  background-color: #ebeefa;
  padding: 1.2rem;
  border: 2px solid #354dbb;
  border-radius: 0 0 4px 4px;
}
.page_generation .lead_box_text {
  font-size: 1.1rem;
  font-weight: 900;
}
.page_generation .lead_box_item {
  margin-top: 0.5rem;
  text-indent: -1rem;
  list-style: none;
}
.page_generation .lead_box_item::before {
  content: "・";
  font-weight: 900;
}
/* page_generation column child */
.column_text + .column_text {
  margin-top: 1rem;
}

/* page_generation product_points */
.page_generation .product_points {
  position: relative;
  margin: 2rem 0;
  padding: 1.2rem;
  border: 4px solid #fbadad;
  border-radius: 12px;
}
.page_generation .product_points_title {
  position: absolute;
  top: -1.5rem;
  left: 0;
  margin-left: 1rem;
  padding: 0.5em;
  background-color: #fff;
  color: #fbadad;
  font-size: 1.1rem;
  font-weight: 900;
}
.page_generation .product_points_icon {
  width: 100px;
  border-radius: 50%;
}
.page_generation .product_points ul {
  list-style: none;
}
.page_generation .product_points ul li {
  margin-bottom: 0.7rem;
}
/* page_generation suggestion */
.page_generation .suggestion .suggestion_head {
  max-width: 560px;
  margin: auto;
  margin-bottom: 2rem;
}
.page_generation .suggestion .suggestion_main_title {
  color: #0045d0;
  font-size: 1.4rem;
  font-weight: bold;
  text-decoration: underline;
}
.page_generation .suggestion .suggestion_title {
  margin: 1rem 0;
}
.page_generation .suggestion .suggestion_title_text {
  color: #df5a01;
  font-size: 1.8rem;
  font-weight: 900;
  padding-top: 0.2em;
}
.page_generation .suggestion .suggestion_title img {
  width: 130px;
  object-fit: cover;
  margin-right: 1rem;
  border: 2px solid #df5a01;
  border-radius: 86px;
}
.page_generation .suggestion_column .content {
  margin-bottom: 2rem;
}
.page_generation .suggestion_column .content img {
  margin-bottom: 1rem;
}
.page_generation .suggestion_column .content-strong {
  padding: 1.5rem;
  border: 3px solid #f57300;
}
.page_generation .suggestion_column .content-strong img {
  display: block;
  width: 90%;
  margin: 0 auto 1rem;
}
.page_generation .ranking_wrapper_point {
  background-color: #fffad1;
  margin-top: 0.5em;
  padding: 0.5em;
}
.page_generation .ranking_title {
  background-color: #ff017f;
  color: #fff;
  margin: 1rem auto;
  padding: 0.6rem 0;
}
.page_generation .ranking_title-dark {
  background-color: #354dbb;
  color: #fff;
  margin: 1rem auto;
  padding: 0.6rem 0;
}
.page_generation .top_viral li {
  padding-top: 10px;
  border-top: none;
}
.page_generation .product_box th {
  width: 100%;
}
.page_generation .rankingverb .rb-img a img {
  display: block;
  height: auto;
  margin: auto;
}
.page_generation .rankingverb .product_detail .product_content {
  width: 100%;
}
.page_generation .rankingverb .product_detail .product_images {
  width: 100%;
}
.page_generation .rankingverb .product_detail .product_images a {
  display: flex;
  align-items: flex-start;
}
.page_generation .rankingverb .product_detail .product_images img {
  max-width: 33%;
  margin: 0;
}
.page_generation .rankingverb .product_detail .product_images img + img {
  margin-left: 0.5em;
}
.page_generation .rankingverb .rb-img-s a img {
  display: block;
  max-width: 60%;
  height: auto;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .page_generation .arrow {
    width: 60%;
  }
  .page_generation .suggestion .suggestion_title img {
    width: 20vw;
    height: 20vw;
  }
  .page_generation .suggestion .suggestion_title_text {
    font-size: 1.1rem;
  }
  .page_generation .rankingverb .rb-img a img {
    width: 100%;
    margin: auto;
    padding: 0;
  }
  .page_generation .rankingverb .rb-img-s a img {
    display: block;
    max-width: 40%;
  }
  .page_generation .product_box {
    border: 2px solid #df5a01;
    border-radius: 6px;
    flex-direction: column;
    box-sizing: border-box;
  }
  .page_generation .product_box th {
    background-image: none;
    background-color: #fcf8ef;
  }
  .page_generation .product_box th,
  .page_generation .product_box td {
    display: block;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  .page_generation .product_box_list {
    width: 96%;
    margin: 1rem auto;
    padding: 0;
    font-size: 0.85rem;
    list-style: none;
  }
  .page_generation .product_box_item + .product_box_item {
    margin-top: 0.3rem;
  }
  .page_generation .product_box_head {
    width: 17%;
    margin-right: 0.3rem;
    padding: 0.2rem;
    background-color: #df5a01;
    color: #fff;
  }
  .page_generation .product_box_title {
    display: block;
  }
  .page_generation .product_box_text {
    display: inline-block;
    width: 83%;
    padding: 0.2rem;
    border: 2px solid #df5a01;
  }
  .page_generation .product_points {
    padding: 0.6em;
  }
}
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  コラム追加ページ
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
.column_title {
  display: block;
  width: 100%;
  margin: 0 auto 10px;
  padding: 8px 10px 6px 25px;
  color: #504233;
  text-align: left;
  font-weight: bold;
  line-height: 28px;
  background: url(../img/back2.png) left top repeat-y;
  clear: both;
}
.column_title p {
  font-size: 19px;
}
.column_text {
  text-align: left;
}
